import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from './store/auth.state';
import { AuthService } from './auth.service';
import { MOCKS_TOKEN } from '@cat-ai-us-fe/shared/util';

export const AuthGuard: CanActivateFn = (): boolean => {
  return redirect();
};

const redirect = (): boolean => {
  const mocksEnaled = inject(MOCKS_TOKEN);
  if (mocksEnaled) {
    return true;
  }

  const store = inject(Store);
  const service = inject(AuthService);
  const isAuthenticated = store.selectSnapshot(AuthState.isAuthenticated);

  if (!isAuthenticated) {
    service.logout();
  }
  return isAuthenticated;
};
