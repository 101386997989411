import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass, TitleCasePipe } from '@angular/common';
import {
  MAIN_SIDEBAR_BUTTONS,
  SETTINGS_SIDEBAR_BUTTONS,
} from './sidebar-menu-items.const';
import { Form, Permission, PermissionsService, User } from '@cat-ai-us-fe/api';
import { FormsService } from '@cat-ai-us-fe/api';
import {
  ActiveUserState,
  RoleAccessDirective,
} from '@cat-ai-us-fe/shared/data-access';
import { Store } from '@ngxs/store';

enum SidebarType {
  ROOT = 'root',
  SETTINGS = 'settings',
}

interface NavigationButton {
  routerLink?: string;
  text: string;
  icon?: string;
  subMenu?: NavigationButton[];
  collapsed?: boolean;
  redirectToMainRoute?: boolean;
  redirectToFirstRoute?: boolean;
  queryParams?: { [key: string]: string | number };
  roles?: User.RoleEnum[];
}
@Component({
  selector: 'cat-ai-aside',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    TitleCasePipe,
    NgClass,
    RoleAccessDirective,
  ],
  templateUrl: './aside.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideComponent implements AfterContentChecked, OnInit {
  public readonly rootButtons: NavigationButton[] = MAIN_SIDEBAR_BUTTONS;
  public readonly settingsButtons: NavigationButton[] =
    SETTINGS_SIDEBAR_BUTTONS;

  public type = SidebarType.ROOT;
  public SidebarType = SidebarType;
  public canChangeForm = false;
  role = this.store.selectSnapshot(ActiveUserState.getRole);

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private readonly formsService: FormsService,
    private store: Store,
    private readonly permissionsService: PermissionsService,
  ) {
    this.permissionsService
      .permissionsList({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageSize: 'null',
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        const permissions = res as unknown as Permission[];
        this.canChangeForm = permissions.some(
          (permission) => permission.codename === 'change_form',
        );
      });
    this.updateSidebarType(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateSidebarType(event.url);
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.fetchForms();
  }

  toggleMenu(link: RouterLinkActive, button: NavigationButton) {
    if (!button.subMenu) {
      return;
    }
    let newRouterLink =
      link.isActive && !button.redirectToMainRoute
        ? this.router.url
        : button.routerLink;

    if (button.redirectToFirstRoute && button.subMenu?.[0]?.routerLink) {
      newRouterLink = button.subMenu[0].routerLink;
    }

    this.router.navigate([newRouterLink]);
    button.collapsed = link.isActive && !button.collapsed;
  }

  private updateSidebarType(url: string) {
    if (url.startsWith('/settings')) {
      this.type = SidebarType.SETTINGS;
    } else {
      this.type = SidebarType.ROOT;
    }
  }

  private fetchForms() {
    this.formsService
      .formsList({
        pageSize: 100,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.renderRecordsMenu(res.results);
      });
  }

  private renderRecordsMenu(forms: Form[]) {
    if (!forms.length) {
      return;
    }

    const recordsIndex = this.rootButtons.findIndex(
      (button) => button.routerLink === '/records',
    );
    const buttonConfig = this.rootButtons[recordsIndex];

    buttonConfig.subMenu = forms.map((form) => ({
      text: form.title || 'N/A',
      routerLink: `/records/${form.id}`,
    }));
  }
}
