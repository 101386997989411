import { Component, Input } from '@angular/core';
import { Notification } from '@cat-ai-us-fe/api';
import { Subject, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NotificationService } from '@cat-ai-us-fe/shared/data-access';
import { NotificationsListComponent } from '@cat-ai-us-fe/shared/ui';

@Component({
  selector: 'cat-ai-notification-sidebar',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, NotificationsListComponent],
  templateUrl: './notification-sidebar.component.html',
})
export class NotificationSidebarComponent {
  @Input() notifications!: Notification[] | null;
  public notificationKind = Notification.KindEnum;
  public notificationUrgency = Notification.UrgencyEnum;
  private destroy$ = new Subject<void>();

  constructor(public notificationService: NotificationService) {}

  dismissAll() {
    this.notificationService
      .dismissAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.notifications?.splice(0, this.notifications.length);
      });
  }

  dismiss(id: number) {
    this.notificationService
      .dismiss(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!this.notifications) {
          return;
        }

        this.notifications.splice(
          this.notifications.findIndex((el) => el.id === data.id),
          1,
        );
      });
  }
}
