import { Route } from '@angular/router';
import { User } from '@cat-ai-us-fe/api';
import { AuthGuard, LoginComponent } from '@cat-ai-us-fe/auth';
import { LayoutComponent } from '@cat-ai-us-fe/core';
import { PermissionGuard, RoleGuard } from '@cat-ai-us-fe/shared/data-access';

export const appRoutes: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        data: { title: 'Dashboard' },
        loadChildren: () =>
          import('@cat-ai-us-fe/dashboard/shell').then(
            (m) => m.DASHBOARD_ROUTES,
          ),
      },
      {
        path: 'generator',
        data: { title: 'Program Code Generator' },
        loadChildren: () =>
          import('@cat-ai-us-fe/code-generator/shell').then(
            (m) => m.GENERATOR_ROUTES,
          ),
      },
      {
        path: 'document-register',
        data: { title: 'Documents' },
        loadComponent: () =>
          import('@cat-ai-us-fe/document-register/shell').then(
            (m) => m.DocumentRegisterShellComponent,
          ),
      },
      {
        path: 'flow-chart',
        data: { title: 'Flow Chart Generator' },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('@cat-ai-us-fe/flow-chart/shell').then(
                (m) => m.FlowChartShellComponent,
              ),
          },
          {
            path: ':flowchartId',
            data: { fullScreenSupervisorOnly: true },
            loadComponent: () =>
              import('@cat-ai-us-fe/flow-chart/shell').then(
                (m) => m.FlowChartShellComponent,
              ),
          },
        ],
      },
      {
        path: 'records',
        data: { title: 'Records' },
        loadChildren: () =>
          import('@cat-ai-us-fe/forms/shell').then((m) => m.FORM_ROUTES),
      },
      {
        path: 'calendar',
        data: { title: 'Calendar', roles: [User.RoleEnum.Supervisor] },
        loadComponent: () =>
          import('@cat-ai-us-fe/calendar/shell').then(
            (m) => m.CalendarShellComponent,
          ),
        canActivate: [RoleGuard],
      },
      {
        path: 'task-manager',
        data: { title: 'Task Management' },
        loadChildren: () =>
          import('@cat-ai-us-fe/task-manager/shell').then(
            (m) => m.TASK_MANAGER_ROUTES,
          ),
      },
      {
        path: 'trainings',
        data: { title: 'Trainings' },
        loadChildren: () =>
          import('@cat-ai-us-fe/trainings/shell').then(
            (m) => m.TRAINING_ROUTES,
          ),
      },
      {
        path: 'settings',
        data: { title: 'Settings' },
        loadChildren: () =>
          import('@cat-ai-us-fe/settings/shell').then((m) => m.SETTINGS_ROUTES),
      },
      {
        path: 'policies',
        data: { title: 'Policies' },
        loadChildren: () =>
          import('@cat-ai-us-fe/policies/shell').then((m) => m.POLICIES_ROUTES),
      },
      {
        path: 'form-builder',
        canActivate: [PermissionGuard],
        data: {
          title: 'Form Builder',
          requiredPermission: 'change_form',
        },
        loadChildren: () =>
          import('@cat-ai-us-fe/form-builder/shell').then(
            (m) => m.FORM_BUILDER_ROUTES,
          ),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
