import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '@cat-ai-us-fe/auth';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { ActiveUserState } from '@cat-ai-us-fe/shared/data-access';
import { RouterLink } from '@angular/router';
import { Notification } from '@cat-ai-us-fe/api';

@Component({
  selector: 'cat-ai-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    AsyncPipe,
    RouterLink,
  ],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle!: string | null;
  @Input() subTitle!: string | null;
  @Input() notifications: Notification[] | undefined;
  @Output() showNotification: EventEmitter<void> = new EventEmitter<void>();
  userName!: string;
  userAbbr!: string;

  constructor(
    private authService: AuthService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.setUserName();
  }

  onLogout() {
    this.authService.logout();
  }

  private setUserName() {
    const user = this.store.selectSnapshot(ActiveUserState.currentUser);
    this.userName = user.fullname || '';
    this.userAbbr = this.userName
      .split(' ')
      .map((n) => n[0])
      .join('');
  }

  togleDiv(event: MouseEvent) {
    this.showNotification.emit();
    event.stopPropagation();
  }
}
