<cat-ai-image-uploader
  [clearForm]="isClearFiles"
  (fileLinks)="onFileLinksUpdates($event)"
  (fileLoading)="isLoading = $event" />
<mat-divider />
<form [formGroup]="supportForm">
  <formly-form
    [fields]="supportFields"
    [model]="supportModel"
    [form]="supportForm"
    [options]="options"
    (modelChange)="onSupportModelUpdates($event)" />
</form>
<cat-ai-form-message
  *ngIf="serverError"
  [message]="serverError"
  type="error"
  class="block mb-2" />
<mat-divider />
<div class="block mt-6">
  <button
    data-testid="close-btn"
    mat-flat-button
    mat-dialog-close
    class="w-2/4"
    data-close-support-form
    (click)="onClose()">
    Close
  </button>
  <button
    data-testid="send-btn"
    mat-flat-button
    color="accent"
    class="w-2/4"
    data-save-support-form
    [disabled]="supportForm.invalid || isLoading"
    (click)="onSend()">
    Send
  </button>
</div>
