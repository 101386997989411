import { Component, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { Login } from '../../data-access/store/auth.actions';
import { Router } from '@angular/router';
import { catchError, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetActiveUser } from '@cat-ai-us-fe/shared/data-access';

@Component({
  selector: 'cat-ai-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  store = inject(Store);
  router = inject(Router);
  error = '';

  readonly form: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  login() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.store
      .dispatch(new Login(this.form.value))
      .pipe(
        catchError((err) => {
          console.log(err);
          if (err instanceof HttpErrorResponse && err.status === 400) {
            this.error = Object.values(err.error).join();
          }
          return throwError(err);
        }),
        switchMap(() => this.store.dispatch(new GetActiveUser())),
      )
      .subscribe(() => this.router.navigate(['./']));
  }
}
