import { User } from '@cat-ai-us-fe/api';
import { MaterialFilterType } from '@cat-ai-us-fe/shared/util';

export const EDITOR_SIDEBAR_BUTTONS = [
  {
    routerLink: '/generator',
    text: 'AI Document code generator',
  },
];

export const DASHBOARD_BUTTONS = [
  {
    routerLink: `/dashboard/material/${MaterialFilterType.UpcomingDueDates}`,
    text: 'Upcoming due dates',
  },
  {
    routerLink: `/dashboard/material/${MaterialFilterType.PendingApprovalDocuments}`,
    text: 'Pending approval documents',
  },
];

export const TRAININGS_BUTTONS = [
  {
    routerLink: `/trainings/dashboard`,
    text: 'Trainings Dashboard',
  },
  {
    routerLink: `/trainings/list`,
    text: 'All trainings',
    roles: [User.RoleEnum.Supervisor],
  },
  {
    routerLink: `/trainings/personal`,
    text: 'My trainings',
  },
  {
    routerLink: `/trainings/participants`,
    text: 'Participants management',
    roles: [User.RoleEnum.Supervisor],
  },
];

export const MAIN_SIDEBAR_BUTTONS = [
  {
    routerLink: '/dashboard',
    text: 'Dashboard',
    icon: 'assessment',
    subMenu: DASHBOARD_BUTTONS,
    redirectToMainRoute: true,
  },
  {
    routerLink: '/records',
    text: 'Records',
    icon: 'article',
    redirectToFirstRoute: true,
  },
  {
    routerLink: '/document-register',
    text: 'Documents',
    icon: 'insert_drive_file',
  },
  {
    routerLink: '/task-manager',
    text: 'Task management',
    icon: 'list_alt',
    queryParams: { type: 'Scheduled' },
  },
  {
    routerLink: '/flow-chart',
    text: 'Flow charts',
    icon: 'schema',
  },
  {
    routerLink: '/trainings',
    text: 'Trainings',
    icon: 'school',
    subMenu: TRAININGS_BUTTONS,
  },
  {
    routerLink: '/calendar',
    text: 'Calendar',
    icon: 'date_range',
    roles: [User.RoleEnum.Supervisor],
  },
  {
    routerLink: '/generator',
    text: 'Editor',
    icon: 'format_shapes',
    subMenu: EDITOR_SIDEBAR_BUTTONS,
  },
];

export const SETTINGS_SIDEBAR_BUTTONS = [
  {
    routerLink: '/settings/company',
    text: 'Company Settings',
    icon: 'domain',
    roles: [User.RoleEnum.Admin, User.RoleEnum.Owner],
  },
  {
    routerLink: '/settings/profile',
    text: 'My Profile',
    icon: 'person',
  },
  {
    routerLink: '/settings/people-management',
    text: 'People Management',
    icon: 'people',
    roles: [User.RoleEnum.Admin, User.RoleEnum.Owner],
  },
  {
    routerLink: '/settings/job-categories',
    text: 'Job Categories',
    icon: 'design_services',
    roles: [User.RoleEnum.Admin, User.RoleEnum.Owner],
  },
  {
    routerLink: '/settings/roles',
    text: 'Roles',
    icon: 'gavel',
    roles: [User.RoleEnum.Admin, User.RoleEnum.Owner],
  },
  {
    routerLink: '/settings/departments',
    text: 'Departments',
    icon: 'warehouse',
    roles: [User.RoleEnum.Admin, User.RoleEnum.Owner],
  },
];
