import { Environment } from './type';
// eslint-disable-next-line @nx/enforce-module-boundaries
import packageJson from '../../../../package.json';

export const environment: Environment = {
  production: false,
  apiRoot: 'https://api.dev.cat-i.ai/api',
  apiUrl: 'https://api.dev.cat-i.ai',
  wsUrl: 'wss://api.dev.cat-i.ai',
  mocksEnabled: false,
  version: `${packageJson.version}-dev`,
};
