{
  "name": "@cat-ai-us-fe/source",
  "version": "2025.1.4",
  "license": "MIT",
  "scripts": {
    "start": "nx run cat-ai-us-fe:serve:local",
    "build:dev": "nx run cat-ai-us-fe:build:development",
    "start:dev": "nx run cat-ai-us-fe:serve:development",
    "start:mocks": "nx run cat-ai-us-fe:serve:mocks",
    "build:staging": "nx run cat-ai-us-fe:build:staging",
    "build:staging:stats": "nx run cat-ai-us-fe:build:staging --stats-json",
    "format:affected": "nx format:write",
    "format:all": "nx format --all",
    "format:check": "nx format:check",
    "lint:prettier": "npx prettier . —-write",
    "lint:affected": "nx affected --target=lint",
    "lint:all": "nx run-many --all --target=lint",
    "lint:fix": "nx affected --target=lint --fix",
    "graph": "nx graph",
    "generate-api-from-specs": "nx run api:generate-data && nx format --all",
    "generate-api-from-specs:dev": "nx run api:generate-data --configuration=development && nx format --all",
    "generate-api-from-specs:stage": "nx run api:generate-data --configuration=staging && nx format --all"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~17.1.0",
    "@angular/cdk": "^17.2.2",
    "@angular/common": "~17.1.0",
    "@angular/compiler": "~17.1.0",
    "@angular/core": "~17.1.0",
    "@angular/forms": "~17.1.0",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "~17.1.0",
    "@angular/platform-browser-dynamic": "~17.1.0",
    "@angular/router": "~17.1.0",
    "@aws-sdk/client-s3": "^3.637.0",
    "@bpmn-io/properties-panel": "^3.19.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/list": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@ngx-formly/core": "^6.3.5",
    "@ngx-formly/material": "^6.3.5",
    "@ngxs/devtools-plugin": "^3.8.2",
    "@ngxs/storage-plugin": "^3.8.2",
    "@ngxs/store": "^3.8.2",
    "@nx/angular": "18.0.5",
    "@videogular/ngx-videogular": "^10.0.1",
    "bpmn-js": "^17.11.1",
    "bpmn-js-properties-panel": "^5.17.1",
    "d3": "^7.9.0",
    "diagram-js": "^15.2.4",
    "highcharts": "^11.4.8",
    "highcharts-angular": "^4.0.1",
    "html2canvas": "^1.4.1",
    "jspdf": "^2.5.2",
    "ng2-pdf-viewer": "^10.3.3",
    "ngx-infinite-scroll": "^17.0.1",
    "ngx-mask": "^17.1.8",
    "ngx-mat-timepicker": "^17.1.0",
    "ngx-quill": "^25.3.2",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "uuid": "^11.0.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~17.1.0",
    "@angular-devkit/core": "~17.1.0",
    "@angular-devkit/schematics": "~17.1.0",
    "@angular-eslint/eslint-plugin": "~17.0.0",
    "@angular-eslint/eslint-plugin-template": "~17.0.0",
    "@angular-eslint/template-parser": "~17.0.0",
    "@angular/cli": "~17.1.0",
    "@angular/compiler-cli": "~17.1.0",
    "@angular/language-service": "~17.1.0",
    "@nx/devkit": "18.0.5",
    "@nx/eslint": "18.0.5",
    "@nx/eslint-plugin": "18.0.5",
    "@nx/jest": "18.0.5",
    "@nx/js": "18.0.5",
    "@nx/playwright": "18.0.5",
    "@nx/workspace": "18.0.5",
    "@openapitools/openapi-generator-cli": "^2.13.5",
    "@playwright/test": "^1.36.0",
    "@schematics/angular": "~17.1.0",
    "@swc-node/register": "~1.8.0",
    "@swc/core": "~1.3.85",
    "@swc/helpers": "~0.5.2",
    "@types/core-js": "^2.5.8",
    "@types/d3": "^7.4.3",
    "@types/jest": "^29.4.0",
    "@types/node": "^18.16.9",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "^6.13.2",
    "@typescript-eslint/parser": "^6.13.2",
    "autoprefixer": "^10.4.0",
    "eslint": "~8.48.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-playwright": "^0.15.3",
    "jest": "^29.4.1",
    "jest-environment-jsdom": "^29.4.1",
    "jest-preset-angular": "~13.1.4",
    "jsonc-eslint-parser": "^2.1.0",
    "ng-packagr": "~17.1.0",
    "nx": "18.0.5",
    "postcss": "^8.4.5",
    "postcss-url": "~10.1.3",
    "prettier": "^3.3.3",
    "tailwindcss": "^3.4.3",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "~5.3.2"
  }
}
