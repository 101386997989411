import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, catchError, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from './store/auth.state';
import { AuthService } from './auth.service';
import { API_ROOT, MOCKS_TOKEN } from '@cat-ai-us-fe/shared/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationCardComponent } from '@cat-ai-us-fe/shared/ui';
import { DEFAULT_NOTIFICATION_DISMISS_DURATION } from '@cat-ai-us-fe/shared/data-access';

const checkUrl = (url: string, apiRoot: string): boolean => {
  const found = url.startsWith(apiRoot);
  return !!found;
};

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const url = req.url.toLowerCase();
  const apiRoot = inject(API_ROOT);

  if (!checkUrl(url, apiRoot)) {
    return next(req);
  }

  const mocksEnabled = inject(MOCKS_TOKEN);

  if (mocksEnabled) {
    return EMPTY;
  }

  const store = inject(Store);
  const authService = inject(AuthService);
  const snackBar = inject(MatSnackBar);

  const token = store.selectSnapshot(AuthState.getToken);

  if (token) {
    const header = 'Token ' + token;
    const headers = req.headers.set('Authorization', header);
    req = req.clone({ headers });
  }

  return next(req).pipe(
    catchError((err) => {
      if (
        err instanceof HttpErrorResponse &&
        err.status === HttpStatusCode.Unauthorized
      ) {
        authService.logout();
      }
      if (err.status === HttpStatusCode.Forbidden) {
        const notification = {
          title: 'Access denied',
          message: err?.error?.detail,
          urgency: 'high',
        };
        showErrorNotification(snackBar, notification);
      }
      if (err.status === HttpStatusCode.NotFound) {
        const notification = {
          title: 'Not found',
          message: err?.error?.detail,
          urgency: 'high',
        };
        showErrorNotification(snackBar, notification);
      }
      if (err.status === HttpStatusCode.InternalServerError) {
        const notification = {
          title: 'Server error',
          message: 'Oops, something went wrong. Please try again later',
          urgency: 'high',
        };
        showErrorNotification(snackBar, notification);
      }
      if (err.status === HttpStatusCode.BadRequest) {
        const errObj = err?.error;
        let message = '';
        Object.keys(errObj).forEach((key, index) => {
          const subject = (errObj[key] as string[])?.join(',');
          if (index) {
            message += `<br/>`;
          }
          message += `${key}: ${subject}`;
        });
        const notification = {
          title: 'Bad request',
          message: message,
          urgency: 'high',
        };
        showErrorNotification(snackBar, notification);
      }
      return throwError(() => err);
    }),
  );
};

const showErrorNotification = (
  snackBar: MatSnackBar,
  notification: {
    title: string;
    message: string;
    urgency: string;
  },
) => {
  snackBar.openFromComponent(NotificationCardComponent, {
    data: { notification },
    horizontalPosition: 'right',
    verticalPosition: 'top',
    panelClass: 'error-snackbar',
    duration: DEFAULT_NOTIFICATION_DISMISS_DURATION,
  });
};
