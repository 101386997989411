import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from './store/auth.actions';
import { Router } from '@angular/router';
import { LoginService } from '@cat-ai-us-fe/api';
import { ClearUser } from '@cat-ai-us-fe/shared/data-access';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private store: Store,
    private router: Router,
    private apiService: LoginService,
  ) {}

  login(payload: { username: string; password: string }) {
    return this.apiService.loginCreate(payload);
  }

  logout() {
    this.store.dispatch(Logout);
    this.store.dispatch(ClearUser);
    this.router.navigate(['/login']);
  }
}
