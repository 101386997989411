import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  SupportImagesFields,
  UploadedFile,
} from '../support-form/support-form.config';
import { FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { MediaCardComponent, MediaConfig } from '@cat-ai-us-fe/shared/ui';
import { UploadService } from '@cat-ai-us-fe/api';
import { UploadAttachmentsService } from '@cat-ai-us-fe/shared/data-access';
import { switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MediaPreviewDialogComponent } from '@cat-ai-us-fe/shared/ui';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'cat-ai-image-uploader',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    MediaCardComponent,
  ],
  templateUrl: './image-uploader.component.html',
})
export class ImageUploaderComponent {
  @Input() set clearForm(value: boolean) {
    if (value) {
      this.options.resetModel?.({ files: [] });
      this.mediaList = [];
      this.links = [];
    }
  }

  @Input() maxQuantity = 6;
  @Output() fileLinks: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() fileLoading: EventEmitter<boolean> = new EventEmitter();
  protected imagesForm: FormGroup = new FormGroup({});
  protected imagesModel: { files: any[] } = {
    files: [],
  };
  protected imagesFields = SupportImagesFields();
  protected options: FormlyFormOptions = {};
  protected mediaList: MediaConfig[] = [];
  private links: string[] = [];
  private readonly destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);

  constructor(
    private readonly uploadService: UploadService,
    private readonly uploadAttachmentsService: UploadAttachmentsService,
  ) {}

  protected onFilesModelChange(model: any) {
    if (this.mediaList.length === 6) {
      return;
    }
    if (model.files.length > 6) {
      model.files.length = 6;
    }
    model.files.forEach((fileItem: any) => {
      if (!fileItem.id) {
        fileItem.id = uuidv4();
      }
    });
    this.imagesModel = model;
    if (model.files.length + this.mediaList.length > 6) {
      this.imagesModel.files.length = 6 - this.mediaList.length;
    }
    this.imagesModel.files.forEach((file: UploadedFile) => {
      const isFileUploaded = this.mediaList.find((item) => item.id === file.id);
      if (isFileUploaded || !file.type.includes('image')) {
        return;
      }
      // 5Mb limit
      if (file.size > 5000000) {
        this.mediaList.push({
          name: file.name,
          type: 'image',
          id: file.id || '',
          src: '',
          status: 'failed',
          errorMsg: `${file.name} is over 5 MB`,
        });
        return;
      }
      this.uploadNewFile(file);
    });
  }

  protected onFileAction(action: 'delete' | 'preview', file: MediaConfig) {
    if (action === 'delete') {
      const fileIdx = this.mediaList.indexOf(file);
      const linkIdx = this.links.indexOf(file.id);
      const modelFile = this.imagesModel.files.find(
        (item) => item.id === file.id,
      );
      const modelIdx = this.imagesModel.files.indexOf(modelFile);

      this.links.splice(linkIdx, 1);
      this.mediaList.splice(fileIdx, 1);
      this.imagesModel.files.splice(modelIdx, 1);
      this.options.resetModel?.({ files: [] });
      this.fileLinks.emit(this.links);
    } else {
      this.onPreviewClick(file);
    }
  }

  protected onPreviewClick(media: MediaConfig) {
    const data = {
      url: media.src,
      fileName: media.name,
      onDelete: () => this.onFileAction('delete', media),
    };
    this.dialog.open(MediaPreviewDialogComponent, {
      data,
      maxWidth: '90vw',
    });
  }

  private uploadNewFile(file: UploadedFile) {
    this.fileLoading.emit(true);
    this.mediaList.push({
      type: 'image',
      id: file.id || '',
      src: '',
      status: 'uploading',
    });
    this.uploadService
      .uploadRetrieve({
        objectName: file.name,
      })
      .pipe(
        switchMap((res: any) =>
          this.uploadAttachmentsService.uploadObject(file, res).pipe(
            tap((url: string) => {
              this.links = [...this.links, url];
              this.fileLinks.emit(this.links);
              this.updateMediaData(file, { id: url });
              file.id = url;
            }),
            switchMap((url: string) => {
              const urlParts = url.split('/');
              const newFileName =
                urlParts[urlParts.length - 2] +
                '/' +
                urlParts[urlParts.length - 1];
              return this.uploadAttachmentsService.resolveAttachmentUrl(
                newFileName,
              );
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (url) => {
          this.updateMediaData(file, { src: url, status: '', name: file.name });
          this.options.resetModel?.({ files: [] });
        },
        error: (err) => {
          this.updateMediaData(file, {
            name: file.name,
            status: 'failed',
            errorMsg: err?.error?.detail || 'File could not be uploaded.',
          });
          this.options.resetModel?.({ files: [] });
        },
        complete: () => {
          this.fileLoading.emit(false);
        },
      });
  }

  private updateMediaData(file: UploadedFile, data: any) {
    const currentMedia = this.mediaList.find((media) => media.id === file.id);
    const newMedia = {
      ...currentMedia,
      ...data,
    };
    this.mediaList.splice(
      this.mediaList.indexOf(<MediaConfig>currentMedia),
      1,
      <MediaConfig>newMedia,
    );
  }
}
