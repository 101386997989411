<div class="h-screen w-screen flex justify-center items-center bg-gray-50">
  <form
    class="flex flex-col gap-10 bg-white border border-gray-200 rounded-md py-10 px-8 shadow-xs"
    [formGroup]="form"
    (ngSubmit)="login()"
  >
    <div class="flex flex-col gap-3 items-center">
      <img src="assets/logo.png" alt="Logo" class="h-10 w-auto mb-6" />

      <h1>Welcome to CAT AI</h1>
      <p class="text-gray-500">
        Please enter your login credentials to continue
      </p>
    </div>
    <div class="flex gap-3 flex-col items-center">
      <mat-form-field class="w-full">
        <input
          type="text"
          matInput
          placeholder="Username"
          formControlName="username"
          autocomplete="username"
        />
      </mat-form-field>

      <mat-form-field class="w-full">
        <input
          type="password"
          matInput
          placeholder="Password"
          formControlName="password"
          autocomplete="current-password"
        />
      </mat-form-field>
      @if (error) {
        <mat-error>{{ error }}</mat-error>
      }

      <button mat-flat-button color="accent" class="mt-6 w-1/2">Login</button>
    </div>
  </form>
</div>
