import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { AsideComponent } from '../aside/aside.component';
import { HeaderComponent } from '../header/header.component';
import { Store } from '@ngxs/store';
import {
  ActiveUserState,
  DEFAULT_NOTIFICATION_DISMISS_DURATION,
  NotificationService,
} from '@cat-ai-us-fe/shared/data-access';
import {
  BehaviorSubject,
  Subject,
  filter,
  map,
  mergeMap,
  takeUntil,
} from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AsyncPipe } from '@angular/common';
import { NotificationCardComponent } from '@cat-ai-us-fe/shared/ui';
import { Notification, User, VersionService } from '@cat-ai-us-fe/api';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { WS_URL_TOKEN } from '@cat-ai-us-fe/shared/util';
import { NotificationSidebarComponent } from '../notification-sidebar/notification-sidebar.component';

@Component({
  selector: 'cat-ai-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    AsideComponent,
    AsyncPipe,
    NotificationCardComponent,
    MatButton,
    MatIcon,
    NotificationSidebarComponent,
  ],
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnDestroy, OnInit, OnDestroy {
  title$ = new BehaviorSubject('');
  subTitle$ = new BehaviorSubject('');
  notifications!: Notification[];
  showSideNotifications = false;
  fullScreenPage = false;
  fullScreenPageSupervisorOnly = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  userRole: User.RoleEnum | null = null;
  UserRole = User.RoleEnum;
  version: string | null = null;

  private _snackBar = inject(MatSnackBar);
  private destroy$ = new Subject<void>();
  private ws: WebSocket | null = null;
  private wsUrl: string = inject(WS_URL_TOKEN);

  @HostListener('document:click')
  closeDiv() {
    if (this.showSideNotifications) {
      this.showSideNotifications = false;
    }
  }

  constructor(
    private store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private titleService: Title,
    private notificationService: NotificationService,
    private readonly versionService: VersionService,
  ) {
    this.handleRouteData();
  }

  ngOnInit(): void {
    this.userRole = this.store.selectSnapshot(ActiveUserState.getRole) ?? null;
    this.setNotifications();
    this.notificationService
      .subscribe()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res.url) {
          return;
        }

        this.ws = new WebSocket(`${this.wsUrl}${res.url}`);

        this.ws.addEventListener('message', (event: MessageEvent<string>) => {
          const data = JSON.parse(event.data) as {
            type: string;
            notification: Notification;
          };
          if (data.type === 'notification') {
            this.openSnackBar(data.notification);
          }
        });
      });

    this.versionService
      .versionRetrieve()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.version = res.commit ?? null;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.ws?.close();
  }

  private handleRouteData() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        this.setPageTitle(event);
        this.fullScreenPage = event.fullScreen;
        this.fullScreenPageSupervisorOnly = event.fullScreenSupervisorOnly;
      });
  }

  private setPageTitle(data: Data) {
    this.titleService.setTitle(`CAT AI | ${data['title']}`);
    this.title$.next(data['title']);
    this.subTitle$.next(data['subTitle']);
  }

  openSnackBar(notification: Notification) {
    this._snackBar.openFromComponent(NotificationCardComponent, {
      data: {
        notification,
        onDismiss: () => {
          this.notificationService.dismiss(notification.id).subscribe();
        },
      },
      duration: DEFAULT_NOTIFICATION_DISMISS_DURATION,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  setNotifications() {
    this.notificationService
      .getNotifications()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.notifications = data.results));
  }

  onToggle() {
    this.showSideNotifications = !this.showSideNotifications;
    if (this.showSideNotifications) {
      this.setNotifications();
    }
  }
}
