import {
  RippleGlobalOptions,
  MAT_RIPPLE_GLOBAL_OPTIONS,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from '@cat-ai-us-fe/api';
import { AuthState } from '@cat-ai-us-fe/auth';
import {
  RadioComponent,
  SelectComponent,
  InputComponent,
  DatepickerComponent,
  TextareaComponent,
  FileComponent,
  FormCardWrapperComponent,
  BodyPartComponent,
  CheckboxComponent,
  RepeatComponent,
  RequestComponent,
  AutocompleteComponent,
  TimepickerComponent,
  FormsDividerComponent,
} from '@cat-ai-us-fe/forms/ui';
import { ActiveUserState, FormsState } from '@cat-ai-us-fe/shared/data-access';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';
import { provideEnvironmentNgxMask } from 'ngx-mask';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TrainingMaterialSelectorComponent } from '@cat-ai-us-fe/trainings/shell';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { API_ROOT, MOCKS_TOKEN } from '@cat-ai-us-fe/shared/util';
import { Validators } from '@angular/forms';
import { FormBuilderSectionComponent } from '@cat-ai-us-fe/form-builder/ui';

function apiConfigFactory(): Configuration {
  const store = inject(Store);
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
    accessToken: store.selectSnapshot(AuthState.getToken),
  };
  return new Configuration(params);
}

export const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

export const formlyConfig: ConfigOption = {
  types: [
    {
      name: 'cat-ai-radio',
      component: RadioComponent,
    },
    {
      name: 'cat-ai-select',
      component: SelectComponent,
    },
    {
      name: 'cat-ai-input',
      component: InputComponent,
    },
    {
      name: 'cat-ai-datepicker',
      component: DatepickerComponent,
    },
    {
      name: 'cat-ai-timepicker',
      component: TimepickerComponent,
    },
    {
      name: 'cat-ai-textarea',
      component: TextareaComponent,
    },
    {
      name: 'cat-ai-file',
      component: FileComponent,
    },
    {
      name: 'cat-ai-body-part',
      component: BodyPartComponent,
    },
    {
      name: 'cat-ai-checkbox',
      component: CheckboxComponent,
    },
    {
      name: 'cat-ai-repeat',
      component: RepeatComponent,
    },
    {
      name: 'cat-ai-request',
      component: RequestComponent,
    },
    {
      name: 'cat-ai-autocomplete',
      component: AutocompleteComponent,
    },
  ],
  wrappers: [
    {
      name: 'cat-ai-form-card',
      component: FormCardWrapperComponent,
    },
    {
      name: 'cat-ai-form-builder-section',
      component: FormBuilderSectionComponent,
    },
    {
      name: 'cat-ai-training-material-selector',
      component: TrainingMaterialSelectorComponent,
    },
    {
      name: 'cat-ai-forms-divider',
      component: FormsDividerComponent,
    },
  ],
  validators: [{ name: 'email', validation: Validators.email }],
  validationMessages: [
    { name: 'email', message: 'Please, enter a valid email' },
  ],
};

export const uiProviders = [
  provideAnimations(),
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
      subscriptSizing: 'dynamic', // has impact on input height
    },
  },
  {
    provide: MAT_SELECT_CONFIG,
    useValue: { hideSingleSelectionIndicator: 'true' },
  },
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  provideEnvironmentNgxMask(),
];

export const authProviders = [
  {
    provide: MOCKS_TOKEN,
    useValue: environment.mocksEnabled,
  },
  {
    provide: API_ROOT,
    useValue: environment.apiRoot,
  },
];

export const storeModules = [
  NgxsModule.forRoot([ActiveUserState, AuthState, FormsState], {
    developmentMode: !environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    key: [
      'auth.token',
      'user.fullname',
      'user.role',
      'user.id',
      'user.company',
    ],
  }),
  NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
];

export const modules = [
  ...storeModules,
  ApiModule.forRoot(apiConfigFactory),
  FormlyModule.forRoot(formlyConfig),
];

export const pipes = [DatePipe];
