import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '@cat-ai-us-fe/auth';
import { appRoutes } from './app.routes';
import { authProviders, modules, pipes, uiProviders } from './app.imports';
import { environment } from '../environments/environment';
import { ENVIRONMENT_TOKEN, WS_URL_TOKEN } from '@cat-ai-us-fe/shared/util';

export const appConfig: ApplicationConfig = {
  providers: [
    ...uiProviders,
    ...authProviders,
    ...pipes,
    importProvidersFrom(modules),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment.apiUrl,
    },
    {
      provide: WS_URL_TOKEN,
      useValue: environment.apiUrl,
    },
  ],
};
