import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '@cat-ai-us-fe/auth';
import { Store } from '@ngxs/store';
import { GetActiveUser } from '@cat-ai-us-fe/shared/data-access';
import { RouterLink } from '@angular/router';
import { Notification, User } from '@cat-ai-us-fe/api';
import { AvatarComponent } from '@cat-ai-us-fe/shared/ui';

@Component({
  selector: 'cat-ai-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    RouterLink,
    AvatarComponent,
  ],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle!: string | null;
  @Input() subTitle!: string | null;
  @Input() notifications: Notification[] | undefined;
  @Output() showNotification: EventEmitter<void> = new EventEmitter<void>();
  userName: string | null = null;
  avatar: string | null = null;

  constructor(
    private authService: AuthService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.setUserName();
  }

  onLogout() {
    this.authService.logout();
  }

  private setUserName() {
    this.store.dispatch(GetActiveUser).subscribe(({ user }: { user: User }) => {
      this.userName = user.fullname || '';
      this.avatar = user.avatar ?? null;
    });
  }

  togleDiv(event: MouseEvent) {
    this.showNotification.emit();
    event.stopPropagation();
  }
}
