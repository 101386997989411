import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'cat-ai-form-builder-section',
  standalone: true,
  imports: [],
  templateUrl: './form-builder-section.component.html',
})
export class FormBuilderSectionComponent extends FieldWrapper {}
