<div
  class="h-screen w-screen flex justify-center flex-col items-center bg-[url('/assets/Login.svg')] bg-no-repeat bg-center bg-cover">
  <div class="flex justify-center mb-4">
    <img src="assets/logo.svg" alt="Logo" class="h-12 w-auto mb-6" />
  </div>
  <div class="max-w-[545px] width-full">
    <form
      class="flex flex-col gap-6 bg-white border border-gray-200 rounded-md py-10 px-8 shadow-xs"
      [formGroup]="form"
      (ngSubmit)="login()">
      <div class="flex flex-col items-start">
        <h1>Login</h1>
        <div class="items-center">
          <img src="assets/login-cover.svg" alt="Logo" class="w-full" />
        </div>
      </div>

      <div class="flex gap-6 flex-col">
        <div>
          <label class="items-start">Email</label>
          <mat-form-field class="w-full items-center">
            <input
              type="text"
              matInput
              placeholder="Email"
              data-testid="username-input"
              formControlName="username"
              autocomplete="username" />
          </mat-form-field>
        </div>
        <div>
          <label class="items-start">Password</label>
          <mat-form-field class="w-full">
            <input
              type="password"
              matInput
              placeholder="Password"
              data-testid="password-input"
              formControlName="password"
              autocomplete="current-password" />
          </mat-form-field>
          @if (error) {
            <mat-error data-testid="error-message">{{ error }}</mat-error>
          }
        </div>
        <div class="remember-me flex w-full justify-between">
          <mat-checkbox data-testid="remember-me-checkbox">
            <span>Remember Me</span>
          </mat-checkbox>
          <a
            class="text-blue-700 cursor-pointer underline"
            data-testid="forgot-password-link">
            Forgot Password?
          </a>
        </div>
        <div class="register-link">
          <p>
            Don`t have an account?
            <a class="text-blue-700 cursor-pointer" data-testid="signup-link">
              Sign-Up
            </a>
          </p>
        </div>
        <button
          mat-flat-button
          color="accent"
          data-testid="login-button"
          class="w-full max-w-[130px] self-end">
          Login
        </button>
      </div>
    </form>
  </div>
</div>
